<template>
  <div>
    <div class="d-flex typography">
      <div class="container">
        <div class="card rounded-0  bg-white px-4 pt-3 pb-6">
          <div class="card-body px-0 pt-4 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-12">

                  <div class="form-login mb-4">

                    <form v-if="address">

                      <div class="form-group mb-2">
                        <label for="telephone">Phone number</label>
                        <input id="telephone" type="text" :class="{ 'is-invalid': errors.telephone }"
                          class="form-control font-size-lg  form-control-lg bg-transparent" v-model="details.telephone"  />
                      
                            <div class="invalid-feedback" v-if="errors.telephone">
                              {{ errors.telephone[0] }}
                            </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="verification_code">Verification Code</label>
                        <table style="width:100%">
                          <tr>

                            <td>
                              <input id="verification_code" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                                :class="{ 'is-invalid': errors.verification_code }" v-model="details.verification_code"
                                placeholder="Enter verification code" />

                              <a type="button" @click="resendVerifyTelephone" :disabled="isResending ? '' : isResending"
                                class="btn">
                                <span v-if="!isResending"> Resend code </span>
                                <span v-if="isResending">
                                  <i class="fas fa-circle-notch fa-spin"></i>
                                  sending...</span>
                              </a>
                              <div class="invalid-feedback" v-if="errors.verification_code">
                                {{ errors.verification_code[0] }}
                              </div>

                            </td>
                            <td>
                              <button type="button" @click="verifyTelephone" :disabled="isLoading ? '' : isLoading"
                                class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                                <span v-if="!isLoading"> Confirm </span>
                                <span v-if="isLoading">
                                  <i class="fas fa-circle-notch fa-spin"></i>
                                  Verifiying...</span>
                              </button>
                            </td>

                          </tr>
                        </table>



                      </div>


                      <div class="form-group mt-6" v-if="errorMessage">
                        
                         <MessageErrorFadeOut v-bind:message="errorMessage" v-bind:show="errorMessage?true:false" />
                      
                      </div>

                    </form>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
 import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  export default {
    name: "ValidateBusinessAddressPhone",
    props: ["partnerId", "canRedirect","redirectTo","address"],
    data: function () {
      return {
        errorMessage: null,
        isLoading: false,
        isResending: false,
        details: {
          verification_code: '',
          telephone: '',
          id:0
        }
      };
    },
    computed: {
      ...mapGetters(["errors", "isSuccess","data"]),
    },
 components: {
      MessageErrorFadeOut
    },
    mounted() {
      
      this.$store.commit("setErrors", {});
       this.$store.commit("setData", {});
      this.$store.commit("setIsSuccess", false);

      if(this.address){

          this.details.telephone = this.address.telephone;
          this.details.id = this.address.id;

             if (this.address.isTelephoneVerified) {
               if (this.canRedirect) {
                  this.$router.push(this.redirectTo).catch(() => {})

                }
            }
      }

    },

    methods: {
      ...mapActions("businessAddress", ["sendVerifyTelephone", 'sendResendVerifyTelephone']),
      resendVerifyTelephone: function () {

        this.isResending = true;
        this.errorMessage=null;

        this.sendResendVerifyTelephone(this.details).then(() => {

          this.isResending = false;

          if (this.errors && this.errors.message) {

            this.errorMessage = this.errors.message;
            this.$notify({
              group: "app",
              type: "error",
              title: "Something went wrong!",
              text: this.errors.message,
              duration: 10000,
              speed: 1000,
            });

          }
          if (this.isSuccess) {

            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text:this.data.data? this.data.data.message:null,
              duration: 10000,
              speed: 1000,
            });

          }


        });
      },

      verifyTelephone: function () {

        this.isLoading = true;
        this.errorMessage=null;

        this.sendVerifyTelephone(this.details).then(() => {

          this.isLoading = false;
          this.errorMessage = this.errors.message;
          if (this.errorMessage) {

       
            this.$notify({
              group: "app",
              type: "error",
              title: "Something went wrong!",
              text: this.errors.message,
              duration: 10000,
              speed: 1000,
            });

          }

          if (!this.errorMessage && this.isSuccess) {

            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Phone number verified successfully",
              duration: 10000,
              speed: 1000,
            });

                  if (this.canRedirect) {

                  this.$router.push(this.redirectTo).catch(() => {})

                }

          }
         

        });
      },
    },
  };
</script>